$(document).ready(function(){
    try{
        var openlogin;
        $("#login_button").bind("mouseenter",function(){
            $("#p_login").css({"display":"block"});
            openlogin = setTimeout(function(){
                 $("#p_login").css({"display":"none"})
            },2000)
        })
        $("#p_login").bind("click",function(){
            clearTimeout(openlogin);
        })
        $('#p_login').bind("mouseleave",function(){
            $("#p_login").css({"display":"none"})
        })
    }catch(e){}	
    let les = $('.mainBox .left .swiper-slide').length;
    for (let index = 0; index < les; index++) {
        $('.mainBox .left .swiper-slide').eq(index).find('img').attr("data",index+1)
    }
    $('.pageBox b').html(les)
    var swiper1 = new Swiper('.mainBox .left', {
        loop:true,
       // autoplay:true,
        navigation: {
            nextEl: '.mainRight',
            prevEl: '.mainLeft',
          },
          on: {
            slideChangeTransitionEnd: function(){
            //   alert(this.activeIndex);//切换结束时，告诉我现在是第几个slide
               $('.pageBox span').html($(".mainBox .left .swiper-slide-active").find('img').attr("data"))
            },
          },
	})
    
    if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
         swiper2 = new Swiper('.cehuaBox', {
            // autoplay: true,//可选选项，自动滑动
            slidesPerView : 1, 
            pagination: {
                el: '.cehuaPage',
                clickable :true
            },
        })
    }else{
       swiper2 = new Swiper('.cehuaBox', {
            // autoplay: true,//可选选项，自动滑动
            slidesPerView : 3, 
            pagination: {
                el: '.cehuaPage',
                clickable :true
            },
        })
    }
    var swiper3 = new Swiper('.tvBox .rightBox', {
		// autoplay: true,//可选选项，自动滑动
        direction: 'vertical',
        slidesPerView : 3,
        spaceBetween : 10,
        navigation: {
            nextEl: '.down',
            prevEl: '.up',
          },
	})
    $(".cehuaBox .swiper-slide .itemBox").css({y:800})
    $(".cehuaBox .swiper-slide").on("mouseenter",function(){
        $(this).find('.itemBox').show().transition({y:0},400);
    })
    $(".cehuaBox .swiper-slide").on("mouseleave",function(){
        $(this).find('.itemBox').transition({y:800},400).hide();
    })
    $('.tvBox .left img').on("mouseenter",function(){
        $(this).transition({scale:1.1},400);
    })
    $('.tvBox .left img').on("mouseleave",function(){
        $(this).transition({scale:1},400);
    })
    $('.tvBox .right .swiper-slide img').on("mouseenter",function(){
        $(this).transition({scale:1.1},400);
    })
    $('.tvBox .right .swiper-slide img').on("mouseleave",function(){
        $(this).transition({scale:1},400);
    })
})